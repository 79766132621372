<template>
  <section class="hero__area hero__height2 hero__height-2 d-flex align-items-center blue-bg-3 p-relative fix">
    <div class="hero__shape">
      <img class="hero-1-circle" src="../../assets/img/shape/hero/hero-1-circle.png" alt="">
      <img class="hero-1-circle-2" src="../../assets/img/shape/hero/hero-1-circle-2.png" alt="">
      <img class="hero-1-dot-2" src="../../assets/img/shape/hero/hero-1-dot-2.png" alt="">
    </div>
    <div class="container">
      <div class="hero__content-wrapper">
        <div class="row align-items-center">
          <div class="col-xxl-7 col-xl-7 col-lg-7 col-md-6">
            <div class="hero__content hero__content-2 p-relative z-index-1">
              <h3 class="hero__title hero__title-2">
                Uw opmerkingen zijn <br>
                <span class="yellow-shape">waardevol <img src="../../assets/img/shape/yellow-bg.png" alt="yellow-shape"> </span>
                voor ons.
              </h3>
              <div class="smiley">
                <img v-on:click="showQR" style="width: 100px" class="" src="../../assets/img/smiley/sm1.png" alt="">
                <img v-on:click="showQR" style="width: 100px" class="" src="../../assets/img/smiley/sm2.png" alt="">
                <img v-on:click="showQR" style="width: 100px" class="" src="../../assets/img/smiley/sm3.png" alt="">
                <img v-on:click="showQR" style="width: 100px" class="" src="../../assets/img/smiley/sm4.png" alt="">
              </div>

              <img v-if="qrVisible" style="width: 200px" class="qr" src="../../assets/img/qr-code.png" alt="">

              <div class="hero__search">

                <div class="comment-wrapper">
                  <div class="comment">
                    <div v-if="showElement1" class="hero__class2 d-none d-lg-flex layer" data-depth="0.3">
                      <div class="hero__class-thumb mr-15">
                        <img src="../../assets/img/hero/hero-2/hero-sm.jpg" alt="">
                      </div>
                      <div class="hero__class-text">
                        <h5>Sertan Sümbül 1</h5>
                        <p class="sinirlayiciYukseklik" v-if="showElement1">Thes Ganesha sofa is amazing. The service that ganezza company provided was impeccable, for sure we will buy again from them. Very satisfied customers</p>
                        <p class="date" v-if="showElement1">Geçen hafta</p>

                        <div class="sirala" v-if="showElement1">
                          <div class="course__rating"><span><i class="fas fa-star"></i></span></div>
                          <div class="course__rating"><span><i class="fas fa-star"></i></span></div>
                          <div class="course__rating"><span><i class="fas fa-star"></i></span></div>
                          <div class="course__rating"><span><i class="fas fa-star"></i></span></div>
                          <div class="course__rating"><span><i class="fas fa-star"></i></span></div>
                        </div>
                      </div>
                      <div class="progress">
                        <div class="progress-bar" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" :style="{width: progressWidth + '%'}"></div>
                      </div>
                    </div>

                  </div>
                  <div class="comment">
                    <div v-if="showElement2" class="hero__class2 d-none d-lg-flex layer" data-depth="0.3">
                      <div class="hero__class-thumb mr-15">
                        <img src="../../assets/img/hero/hero-2/hero-sm.jpg" alt="">
                      </div>
                      <div class="hero__class-text">
                        <h5>Sertan Sümbül 2</h5>
                        <p class="sinirlayiciYukseklik" v-if="showElement2">Thes Ganesha sofa is amazing. The service that ganezza company provided was impeccable, for sure we will buy again from them. Very satisfied customers</p>
                        <p class="date" v-if="showElement2">Geçen hafta</p>

                        <div class="sirala" v-if="showElement2">
                          <div class="course__rating"><span><i class="fas fa-star"></i></span></div>
                          <div class="course__rating"><span><i class="fas fa-star"></i></span></div>
                          <div class="course__rating"><span><i class="fas fa-star"></i></span></div>
                          <div class="course__rating"><span><i class="fas fa-star"></i></span></div>
                          <div class="course__rating"><span><i class="fas fa-star"></i></span></div>
                        </div>
                      </div>
                      <div class="progress">
                        <div class="progress-bar" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" :style="{width: progressWidth + '%'}"></div>
                      </div>
                    </div>

                  </div>
                  <div class="comment">
                    <div v-if="showElement3" class="hero__class2 d-none d-lg-flex layer" data-depth="0.3">
                      <div class="hero__class-thumb mr-15">
                        <img src="../../assets/img/hero/hero-2/hero-sm.jpg" alt="">
                      </div>
                      <div class="hero__class-text">
                        <h5>Sertan Sümbül 3</h5>
                        <p class="sinirlayiciYukseklik" v-if="showElement3">Thes Ganesha sofa is amazing. The service that ganezza company provided was impeccable, for sure we will buy again from them. Very satisfied customers</p>
                        <p class="date" v-if="showElement3">Geçen hafta</p>

                        <div class="sirala" v-if="showElement3">
                          <div class="course__rating"><span><i class="fas fa-star"></i></span></div>
                          <div class="course__rating"><span><i class="fas fa-star"></i></span></div>
                          <div class="course__rating"><span><i class="fas fa-star"></i></span></div>
                          <div class="course__rating"><span><i class="fas fa-star"></i></span></div>
                          <div class="course__rating"><span><i class="fas fa-star"></i></span></div>
                        </div>
                      </div>
                      <div class="progress">
                        <div class="progress-bar" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" :style="{width: progressWidth + '%'}"></div>
                      </div>
                    </div>
                  </div>
                  <div class="comment">
                    <div v-if="showElement4" class="hero__class2 d-none d-lg-flex layer" data-depth="0.3">
                      <div class="hero__class-thumb mr-15">
                        <img src="../../assets/img/hero/hero-2/hero-sm.jpg" alt="">
                      </div>
                      <div class="hero__class-text">
                        <h5>Sertan Sümbül 4</h5>
                        <p class="sinirlayiciYukseklik" v-if="showElement4">Thes Ganesha sofa is amazing. The service that ganezza company provided was impeccable, for sure we will buy again from them. Very satisfied customers</p>
                        <p class="date" v-if="showElement4">Geçen hafta</p>

                        <div class="sirala" v-if="showElement4">
                          <div class="course__rating"><span><i class="fas fa-star"></i></span></div>
                          <div class="course__rating"><span><i class="fas fa-star"></i></span></div>
                          <div class="course__rating"><span><i class="fas fa-star"></i></span></div>
                          <div class="course__rating"><span><i class="fas fa-star"></i></span></div>
                          <div class="course__rating"><span><i class="fas fa-star"></i></span></div>
                        </div>
                      </div>
                      <div class="progress">
                        <div class="progress-bar" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" :style="{width: progressWidth + '%'}"></div>
                      </div>
                    </div>
                  </div>
                  <div class="comment">
                    <div v-if="showElement5" class="hero__class2 d-none d-lg-flex layer" data-depth="0.3">
                      <div class="hero__class-thumb mr-15">
                        <img src="../../assets/img/hero/hero-2/hero-sm.jpg" alt="">
                      </div>
                      <div class="hero__class-text">
                        <h5>Sertan Sümbül 5</h5>
                        <p class="sinirlayiciYukseklik" v-if="showElement5">Thes Ganesha sofa is amazing. The service that ganezza company provided was impeccable, for sure we will buy again from them. Very satisfied customers</p>
                        <p class="date" v-if="showElement5">Geçen hafta</p>

                        <div class="sirala" v-if="showElement5">
                          <div class="course__rating"><span><i class="fas fa-star"></i></span></div>
                          <div class="course__rating"><span><i class="fas fa-star"></i></span></div>
                          <div class="course__rating"><span><i class="fas fa-star"></i></span></div>
                          <div class="course__rating"><span><i class="fas fa-star"></i></span></div>
                          <div class="course__rating"><span><i class="fas fa-star"></i></span></div>
                        </div>
                      </div>
                      <div class="progress">
                        <div class="progress-bar" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" :style="{width: progressWidth + '%'}"></div>
                      </div>
                    </div>
                  </div>

                </div>

              </div>

            </div>
          </div>
          <div class="col-xxl-5 col-xl-5 col-lg-5 col-md-5">
            <div class="hero__thumb-wrapper mb--120">
              <div class="hero__thumb-2 scene">
                <img class="hero-big" src="../../assets/img/hero/hero-2/preWorker.png" alt="">
                <img class="hero-shape-purple" src="../../assets/img/hero/hero-2/hero-shape-purple.png" alt="">



                <div class="hero__mic2">
                        <span>
                <img style="width: 250px" class="" src="../../assets/img/hero/hero-2/logo.png" alt="">

                        </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<style>
.hero__height2 {
  height: 100vh;
}
.hero-big{
  width: 600px !important;
}

.sirala {
  display: flex;
  margin-bottom: 10px;
  justify-content: end;
  margin-top: 15px;
}

.date {
  float: left;
  display: flex;
  justify-content: end;
  margin-top: 15px;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
.sinirlayiciYukseklik{
  height: 100px;
}
.progress {
  width: 100%;
  height: 5px;
  position: absolute;
  bottom: 0;
  left: 2px;
  background-color: #ddd;
  transition: width 4s;

}
.progress-bar {
  background-color: #ff6606;
  transition: width 4s;
}

.comment-wrapper {
  transition: all 2s ease;
}

.comment:hover {
  transform: scale(1.1);
  box-shadow: 0px 0px 10px #ccc;
}
.hero__title{
  text-align: center;
}
.smiley{
  text-align: center;
}
.hero__mic2{
  position: absolute;
  top: -10%;
  -webkit-animation-duration: 2.5s;
  animation-duration: 2.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: hero-bounce;
  animation-name: hero-bounce;
  left: -56% !important;
}
.qr{
  text-align: center;
  display: block;
  margin: 0 auto;
  margin-bottom: 20px;
}
.hero__search{
  margin-bottom: 100px;
}
.hero__class2{
  position: absolute;
  left: auto !important;
  top: auto !important;
  /* bottom: -28%; */
  right: 0%;
  box-shadow: 0px 30px 60px 0px rgb(1 11 60 / 14%);
  background: rgba(255, 255, 255, 0.9);
  border-radius: 4px;
  padding: 20px;
}
.smiley img{
  margin-right: 20px;
  margin-bottom: 20px;
}
</style>

<script>
export default {
  data() {
    return {
      showElement1: true,
      showElement2: false,
      showElement3: false,
      showElement4: false,
      showElement5: false,
      currentElement: 1,
      progressWidth: 0,
      qrVisible: false


    }
  },
  methods: {
    rotateElements: function() {
      if (this.showElement1) {
        this.showElement1 = false;
        this.showElement2 = true;
      } else if (this.showElement2) {
        this.showElement2 = false;
        this.showElement3 = true;
      } else if (this.showElement3) {
        this.showElement3 = false;
        this.showElement4 = true;
      } else if (this.showElement4) {
        this.showElement4 = false;
        this.showElement5 = true;
      } else {
        this.showElement5 = false;
        this.showElement1 = true;
      }

    },
    showQR() {
      this.qrVisible = true;
      setTimeout(() => {
        this.qrVisible = false;
      }, 15000);
    },
  },
  watch: {
    showElement1: function(val) {
      if (val) {
        this.progressWidth = 0;
      }
    },
    showElement2: function(val) {
      if (val) {
        this.progressWidth = 0;
      }
    },
    showElement3: function(val) {
      if (val) {
        this.progressWidth = 0;
      }
    },
    showElement4: function(val) {
      if (val) {
        this.progressWidth = 0;
      }
    },
    showElement5: function(val) {
      if (val) {
        this.progressWidth = 0;
      }
    }
  },

  created() {
    let intervalId = setInterval(() => {
      if (this.progressWidth >= 200) {

        clearInterval(intervalId)

      } else {
        this.progressWidth += 60
      }
    }, 1000)
  },

  mounted() {
    setInterval(() => {
      if (this.currentElement === 5) {
        this.currentElement = 1
      } else {
        this.currentElement += 1
      }
      this.showElement1 = this.currentElement === 1
      this.showElement2 = this.currentElement === 2
      this.showElement3 = this.currentElement === 3
      this.showElement4 = this.currentElement === 4
      this.showElement5 = this.currentElement === 5
    }, 4000)
  }
}
</script>

